import React from 'react'
import Helmet from "react-helmet"
import { inject, observer } from "mobx-react"
import ReactMarkdown from "react-markdown/with-html"

import en_contact from "../../content/en_contact.json"
import de_contact from "../../content/de_contact.json"

import Layout from '../components/layout';
import colors from '../components/colors';

import { ReactTypeformEmbed } from 'react-typeform-embed';

class ContactPage extends React.Component{
    constructor(props) {
        super(props)
          this.openForm = this.openForm.bind(this);
        }
      
        openForm() {
          this.typeformEmbed.typeform.open();
        }

    render(){
    const { device, language } = this.props.store
    const clientHeight = typeof document !== 'undefined' && document.documentElement.clientHeight ? document.documentElement.clientHeight : 350
    return (
        <Layout>
        <Helmet>
          <title>{'LT | Contact'}</title>
        </Helmet>
        
        
            <div style={{width:'100%',backgroundColor:colors.greyBackground, flex:1, display:device==='mobile'? 'inline-block': 'flex', flexDirection:'column', justifyContent:'space-evenly'}}>
            
                <div style={{margin:'auto', width:'100%', display:'flex', justifyContent:'center', alignItems:'center', flex:1, padding:'0px 0px 0px 0px', height:device === 'desktop'?clientHeight-250:clientHeight-280}}>
                <ReactTypeformEmbed
          url="https://form.typeform.com/to/mdf6P9cR?typeform-medium=embed-snippet"
          hideHeaders
          hideFooter
          style={{position:'relative'}}
          ref={tf => {
            this.typeformEmbed = tf;
          }}
        />

                </div>

            </div>
        </Layout>
    )
}
}

export default inject("store")(observer(ContactPage))